<template>
  <ul :id="id" class="legend_list">
    <li v-for="(item, index) in legend" :key="index">
      <!--line-->
      <svg
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2598"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="200"
        height="200"
        v-if="legendType[index] == 'line'"
      >
        <path
          d="M896 437.568l-128 0c-5.056 0-9.536 1.792-14.336 2.88C722.432 334.336 625.28 256 509.056 256 392.96 256 295.808 334.272 264.576 440.32c-4.608-1.024-8.96-2.752-13.824-2.752L128 437.568c-35.392 0-64 28.608-64 64s28.608 64 64 64l122.752 0c2.752 0 5.12-1.216 7.744-1.536C282.624 680.256 385.728 768 509.056 768c123.392 0 226.624-87.808 250.752-204.096C762.624 564.288 765.056 565.568 768 565.568l128 0c35.328 0 64-28.608 64-64S931.328 437.568 896 437.568zM509.056 704C403.2 704 317.12 617.856 317.12 512S403.2 320 509.056 320c105.856 0 192.064 86.144 192.064 192S614.912 704 509.056 704z"
          :fill="color(legend[index])"
          :class="`legend${index + 1}`"
        ></path>
      </svg>

      <!--dot-->
      <svg
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1225"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="200"
        height="200"
        v-if="legendType[index] == 'dot'"
      >
        <path
          d="M512 520.064m-414.464 0a3.238 3.238 0 1 0 828.928 0 3.238 3.238 0 1 0-828.928 0Z"
          p-id="1256"
          :fill="color(legend[index])"
          :class="`legend${index + 1}`"
        ></path>
      </svg>

      <!--dot_line-->
      <svg
        class="icon"
        viewBox="0 0 48.651 6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2598"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="49"
        height="6"
        v-if="legendType[index] == 'dot_line'"
      >
        <path
          class="a"
          :stroke="color(legend[index])"
          style="fill: none; stroke-linecap: round; stroke-width: 6px; stroke-dasharray: 12 12"
          d="M-9953-15380h42.651"
          transform="translate(9956 15383)"
        />
      </svg>

      <!--bar-->
      <svg
        class="icon"
        viewBox="0 0 22 27"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="21.004"
        height="27"
        v-if="legendType[index] == 'bar'"
      >
        <path
          :fill="color(legend[index])"
          :class="`legend${index + 1}`"
          d="M10443,18438v-2h3v-25h15v25h3v2Z"
          transform="translate(-10442.998 -18411)"
        />
      </svg>

      <!--pie-->
      <svg
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="200"
        height="200"
        v-if="legendType[index] == 'pie'"
      >
        <path
          d="M512 85.333333C320 85.333333 140.8 149.333333 0 256l512 682.666667L1024 256C883.2 149.333333 704 85.333333 512 85.333333z"
          :fill="color(legend[index])"
          :class="`legend${index + 1}`"
        ></path>
      </svg>

      <span :style="`color:${color(legend[index])}`" :class="`legend_txt${index + 1}`">{{ item }}</span>
    </li>
  </ul>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum } from "@/utils/utils.js";
import anime from "animejs/lib/anime.es.js";
export default {
  props: ["id"],
  data() {
    return {
      legend: [],
      legendType: [],
    };
  },
  methods: {
    async playOut() {
      anime({
        targets: document.querySelectorAll(`#${this.id} li`),
        duration: 200,
        delay: anime.stagger(30),
        easing: "easeOutExpo",
        autoplay: true,
        opacity: [1, 0],
      });
    },

    //核心库
    async createLegend({
      legend,
      legendType,
      colors, //label的颜色数组。如果不传则默认为schemeSpectral
    } = {}) {
      if (colors === undefined) {
        this.color = d3.scaleOrdinal(d3.schemeTableau10);
      } else {
        this.color = d3.scaleOrdinal(legend, colors);
      }
      this.legend = legend;
      if(typeof legendType=='string'){
        legend.map(item=>{
          this.legendType.push(legendType);
        })
      }else{
        this.legendType = legendType;
      }

      this.$nextTick(() => {
        anime({
          targets: document.querySelectorAll(`#${this.id} li`),
          duration: 900,
          delay: anime.stagger(80),
          easing: "easeOutExpo",
          autoplay: true,
          opacity: [0, 1],
        });
      });
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.legend_list {
  li {
    display: inline-block;
    margin-left: 10px;
    svg {
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      position: relative;
      top: -1px;
      margin-left: 4px;
    }
  }
}
.icon {
  width: 28px;
  height: 28px;
}
</style>
