<template>
  <div class="holder">
    <h1>line 默认不配置颜色，会使用默认色系</h1>
    <h1><span @click="playIn1('refChartLegend1')">入场</span><span @click="playOut('refChartLegend1')">出场</span></h1>
    <chart-legend ref="refChartLegend1" id="legend1"></chart-legend>
    <!--特别注意！ id 必填，并且每个都不一样-->

    <h1>bar，如果使用了自定义颜色配置，则会覆盖默认色系。如果自定义颜色不够用会自己循环</h1>
    <h1><span @click="playIn2('refChartLegend2')">入场</span><span @click="playOut('refChartLegend2')">出场</span></h1>
    <chart-legend ref="refChartLegend2" id="legend2"></chart-legend>

    <h1>混合</h1>
    <h1><span @click="playIn3('refChartLegend3')">入场</span><span @click="playOut('refChartLegend3')">出场</span></h1>
    <chart-legend ref="refChartLegend3" id="legend3"></chart-legend>

    <h1>pie</h1>
    <h1><span @click="playIn4('refChartLegend4')">入场</span><span @click="playOut('refChartLegend4')">出场</span></h1>
    <chart-legend ref="refChartLegend4" id="legend4"></chart-legend>

    <h1>line</h1>
    <h1><span @click="playIn5('refChartLegend5')">入场</span><span @click="playOut('refChartLegend5')">出场</span></h1>
    <chart-legend ref="refChartLegend5" id="legend5"></chart-legend>
  </div>
</template>
<script>
import chartLegend from "@/components/chartLegend/index.vue";
import { sleep, randomNum, setRem } from "@/utils/utils.js";
export default {
  data: function () {
    return {
      population2: [
        { name: "互联网传媒", value: 446, type: "line", label1: "销售额", label2: "环比增长" },
        { name: "通信", value: 936 },
        { name: "化学制药", value: 323 },
        { name: "光学电子", value: 336 },
        { name: "白酒", value: 236 },
        { name: "媒体", value: 256 },
        { name: "白酒7", value: 127 },
        { name: "白酒6", value: 227 },
        { name: "白酒9", value: 67 },
        { name: "白酒8", value: 227 },
      ],
      population1: {
        legend: {
          data: ["Evaporation", "Precipitation", "Temperature"],
        },
        name: ["互联网传媒", "通信", "化学制药", "光学电子", "白酒", "媒体", "通信"],
        series: [
          {
            label: "",
          },
        ],
      },
    };
  },
  components: { chartLegend },
  methods: {
    playIn1(refName) {
      this.$refs[refName].createLegend({
        legend: ["互联网传媒", "通信", "化学制药", "光学电子", "白酒", "媒体", "通信"],
        legendType: ["line", "line", "line", "line", "line", "line", "line"],
        // colors: ["red", "green", "blue"], //不写默认为黑色
      });
    },
    playIn2(refName) {
      this.$refs[refName].createLegend({
        legend: ["销售额", "环比增长"],
        legendType: ["bar", "bar"],
        colors: ["red", "green", "blue"], //不写默认为黑色
      });
    },
    playIn3(refName) {
      this.$refs[refName].createLegend({
        legend: ["销售额", "环比增长", "低估区", "高估区"],
        legendType: ["bar", "line", "dot", "dot"],
        colors: ["red", "green", "blue","orange"], //不写默认为黑色
      });
    },
    playIn4(refName) {
      this.$refs[refName].createLegend({
        legend: ["互联网传媒", "通信", "化学制药", "光学电子", "白酒", "媒体", "通信"],
        legendType: ["pie", "pie", "pie", "pie", "pie", "pie", "pie"],
        // colors: ["red", "green", "blue"], //不写默认为黑色
      });
    },
    playIn5(refName) {
      this.$refs[refName].createLegend({
        legend: ["互联网传媒", "通信", "化学制药", "光学电子", "白酒", "媒体", "通信"],
        legendType: "line",
        // colors: ["red", "green", "blue"], //不写默认为黑色
      });
    },
    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {
    //每个项目都可以独立设置
    setRem(780);
  },
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h1 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h2 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  .new {
    color: #fff;
    background: #f00;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    margin-right: 0.625rem;
  }
}
p {
  margin-bottom: 20px;
}
</style>
